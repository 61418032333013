import { useEffect, useState } from "react";

// import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
import { FrogProvider as AuthProvider } from "contexts/FrogAuthContext";
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { LocalAuthProvider as AuthProvider } from 'contexts/LocalContext';
// auth-provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import Loader from "components/Loader";
import Locales from "components/Locales";
import Notistack from "components/third-party/Notistack";
import RTLLayout from "components/RTLLayout";
// project-imports
import Routes from "routes";
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import ThemeCustomization from "themes";

// import Customization from 'components/Customization';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  {/* <Customization /> */}
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
