// import admin from "./admin";
// import client from "./client";
// import clients from "./clients";
import dashboard from "./fdashboard";
// import widget from './bulk-messages';
// import { get } from "lodash";
import services from "./services";
import settings from "./settings";
// project-imports
// import applications from './applications';
// import chartsMap from './charts-map';
// import formsTables from './forms-tables';
// import support from './support';
// import pages from './pages';

// const user = JSON.parse(localStorage.getItem("user"));
// console.log("🚀 ~ user:", user);
// const usertype = get(user, "data[0].useraccount.usertype");

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, services, settings]
};

// // Check user type and modify menuItems accordingly
// if (user && usertype === "admin") {
//   // If user type is admin, include additional menu items
//   menuItems.items.push(admin);
// } else if (user && usertype === "CLIENT") {
//   // If user type is client, include additional menu items
//   menuItems.items.push(dashboard);
//   menuItems.items.push(services);
//   menuItems.items.push(settings);
// }

export default menuItems;
