// import { useLocation, useNavigate } from "react-router-dom";

// import PropTypes from "prop-types";
// // project-imports
// import useAuth from "hooks/useAuth";
// import { useEffect } from "react";

// // ==============================|| AUTH GUARD ||============================== //

// const AuthGuard = ({ children }) => {
//   const { isLoggedIn } = useAuth();
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     if (!isLoggedIn) {
//       navigate("login", {
//         state: {
//           from: location.pathname
//         },
//         replace: true
//       });
//     }
//   }, [isLoggedIn, navigate, location]);

//   return children;
// };

// AuthGuard.propTypes = {
//   children: PropTypes.node
// };

// export default AuthGuard;

import { useLocation, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { get } from "lodash";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";

const AuthGuard = ({ children, allowedRoles }) => {
  const { isLoggedIn, user } = useAuth();
  const usertype = get(user, "data[0].useraccount.usertype");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", {
        state: {
          from: location.pathname
        },
        replace: true
      });
    } else if (allowedRoles && !allowedRoles.includes(usertype)) {
      navigate("/404");
    }
  }, [isLoggedIn, navigate, location, user, allowedRoles, usertype]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  allowedRoles: PropTypes.arrayOf(PropTypes.string)
};

export default AuthGuard;
