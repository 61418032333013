import { HASERROR, LOGIN, LOGOUT } from "store/reducers/actions";
import { createContext, useEffect, useReducer } from "react";

// third-party
// import { Chance } from "chance";
// project-imports
import Loader from "components/Loader";
import PropTypes from "prop-types";
import authReducer from "store/reducers/auth";
import axios from "utils/axios";
import { get } from "lodash";
import useLocalStorage from "hooks/useLocalStorage";

// import { useNavigate } from "react-router";

// const chance = new Chance();

// constant
const initialState = {
  error: null,
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  clientid: null,
  token: null,
  emailCode: null,
  phoneCode: null,
  forgotpassword: null,
  checkUserStatus: null
};

const setBearerToken = (bearerToken) => {
  if (bearerToken) {
    localStorage.setItem("bearerToken", bearerToken);
    axios.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;
  } else {
    localStorage.removeItem("bearerToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setSession = (user) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `User ${user}`;
  } else {
    localStorage.removeItem("user");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setClientRegister = (user) => {
  if (user) {
    localStorage.setItem("register", JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Client Register ${user}`;
  } else {
    localStorage.removeItem("register");
    delete axios.defaults.headers.common.Authorization;
  }
};
const TOKEN_CHECK_INTERVAL = 60000;
const bearerToken = localStorage.getItem("bearerToken");
const user = JSON.parse(localStorage.getItem("user"));
// ==============================|| FROG CONTEXT & PROVIDER ||============================== //

const FrogAuthContext = createContext(null);

export const FrogProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { clearLocalStorage } = useLocalStorage();

  // const navigate = useNavigate();

  const checkTokenExpiry = () => {
    if (bearerToken && user) {
      const expires = get(user, "data[0].accessToken.expiresOn");
      const expirationTime = new Date(expires).getTime();
      const currentTime = new Date().getTime();

      if (currentTime > expirationTime) {
        logout();
      }
    }
  };

  const logoutIfTokenExpired = () => {
    const bearerToken = localStorage.getItem("bearerToken");
    const user = localStorage.getItem("user");

    if (bearerToken && user) {
      checkTokenExpiry();
    }
  };

  useEffect(() => {
    const tokenCheckInterval = setInterval(() => {
      logoutIfTokenExpired();
    }, TOKEN_CHECK_INTERVAL);

    return () => clearInterval(tokenCheckInterval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const bearerToken = localStorage.getItem("bearerToken");
        setBearerToken(bearerToken);
        if (bearerToken) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        // console.error(err);
        dispatch({
          type: HASERROR,
          payload: {
            error: err
          }
        });
      }
    };

    init();
  }, []);

  const validateResetPasswordLink = async (encrypteduserid, encryptedtoken) => {
    const response = await axios.put(`/auth/resetpassword/validatelink/${encrypteduserid}/${encryptedtoken}`);
    return response.data;
  };

  /**
   * Resets the user's password.
   *
   * @param {Object} data - The data containing the necessary information for resetting the password.
   * @returns {Promise} - A promise that resolves to the response data from the server.
   */
  const resetPassword = async (data) => {
    const response = await axios.post("/auth/resetpassword", data);
    return response.data;
  };

  /**
   * Changes the password for the user.
   *
   * @param {Object} data - The data containing the new password.
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   */
  const changePassword = async (data) => {
    const response = await axios.post("/auth/changepassword", data);
    return response.data;
  };

  /**
   * Checks the status of a user.
   *
   * @param {string} username - The username of the user to check.
   * @returns {Promise<any>} - A promise that resolves to the user's status.
   */
  const checkUserStatus = async (username) => {
    const response = await axios.get(`/auth/status/username/${username}`);
    return response.data;
  };

  /**
   * Checks the user status based on the provided email.
   * @param {string} email - The email of the user.
   * @returns {Promise<any>} - A promise that resolves to the user status data.
   */
  const checkUserStatusEmail = async (email) => {
    const response = await axios.get(`/auth/status/email/${email}`);
    return response.data;
  };

  /**
   * Logs in a user with the provided username and password.
   * @param {string} username - The username of the user.
   * @param {string} password - The password of the user.
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   */
  const login = async (username, password) => {
    const response = await axios.post("/auth/token", { username, password });
    const user = response.data;
    // console.log("🚀 ~ login ~ user:", user);

    const clientid = get(user, "data[0].useraccount.clientid");
    // console.log("🚀 ~ login ~ clientid:", clientid);
    const userToken = get(user, "data[0].accessToken.token", "");
    // console.log("🚀 ~ login ~ userToken:", userToken);

    setBearerToken(userToken);
    setSession(user);
    setClientRegister(null);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
        clientid,
        userToken
      }
    });

    return response.data;
  };

  // const login = async (username, password) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const response = axios.post("/auth/token", { username, password });
  //       const user = response.data;

  //       const userToken = get(user, "data[0].accessToken.token", "");

  //       setBearerToken(userToken);
  //       setSession(user);
  //       setClientRegister(null);

  //       resolve(user);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  /**
   * Sends a pre-registration email OTP to the specified email address.
   * @param {string} email - The email address to send the OTP to.
   * @returns {Promise<any>} - A promise that resolves to the response data.
   */
  const preregisteremail = async (email) => {
    const response = await axios.put(`/auth/preregsendemailotp/${email}`);
    return response.data;
  };

  /**
   * Prevalidates an email address with a code.
   * @param {Object} data - The data object containing the email and code.
   * @param {string} data.email - The email address to be prevalidated.
   * @param {string} data.code - The code to be used for prevalidation.
   * @returns {Promise<any>} - A promise that resolves to the response data.
   */
  const prevalidateemail = async (data) => {
    const { email, code } = data;
    const response = await axios.post("/auth/prevalidate/email", {
      email,
      code
    });
    return response.data;
  };

  /**
   * Sends a pre-registration phone OTP request.
   * @param {string} phonenumber - The phone number to send the OTP to.
   * @returns {Promise<any>} - A promise that resolves to the response data.
   */
  const preregisterphonenumber = async (phonenumber) => {
    const response = await axios.put(`/auth/preregsendphoneotp/${phonenumber}`);
    return response.data;
  };

  /**
   * Prevalidates an email by making a POST request to the server.
   * @param {Object} data - The data object containing the phone and code.
   * @param {string} data.msisdn - The phone number.
   * @param {string} data.code - The code.
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   */
  const prevalidatephonenumber = async (data) => {
    const { msisdn, code } = data;
    const response = await axios.post("/auth/prevalidate/msisdn", {
      msisdn,
      code
    });
    return response.data;
  };

  /**
   * Registers a user.
   */
  const register = async ({
    name,
    email,
    phonenumber,
    location,
    iscampany,
    username,
    password,
    countryid,
    referralcode,
    clienttype,
    allowmarketing,
    headaboutwigal,
    emailotp,
    phoneotp
  }) => {
    const response = await axios.post("/clients/register", {
      name,
      email,
      phonenumber,
      location,
      iscampany,
      username,
      password,
      countryid,
      referralcode,
      clienttype,
      allowmarketing,
      headaboutwigal,
      phoneotp,
      emailotp
    });

    setClientRegister(response.data);

    return response.data;
  };

  /**
   * Verifies the email with the provided code.
   * @param {Object} params - The parameters for email verification.
   * @param {string} params.email - The email to be verified.
   * @param {string} params.code - The verification code.
   * @returns {Promise<string>} - A promise that resolves to the email verification code.
   */
  const emailVerification = async (data) => {
    const { email, code } = data;
    const response = await axios.post("/auth/validate/email", {
      email,
      code
    });
    return response.data;
  };

  /**
   * Resends the email verification code for a user.
   * @param {string} userid - The ID of the user.
   * @returns {Promise<any>} - A promise that resolves to the response data.
   */
  const resendEmailCode = async (userid) => {
    const response = await axios.put(`auth/resendemailcode/userid/${userid}`);
    let data = response.data;

    return data;
  };

  /**
   * Performs phone verification by sending the provided MSISDN and code to the server.
   * @param {Object} params - The parameters for phone verification.
   * @param {string} params.msisdn - The MSISDN (Mobile Station International Subscriber Directory Number) to be verified.
   * @param {string} params.code - The verification code to be sent to the server.
   * @returns {Promise<string>} - A promise that resolves with the phone code received from the server.
   */
  const phoneVerification = async ({ msisdn, code }) => {
    const response = await axios.post("/auth/validate/msisdn", {
      msisdn,
      code
    });
    let phoneCode = response.data;
    return phoneCode;
  };

  const resendPhoneCode = async (userid) => {
    const response = await axios.put(`/auth/resendmsisdncode/userid/${userid}`);

    return response.data;
  };

  /**
   * Resets the user's password.
   * @async
   * @function forgotPassword
   * @returns {Promise<void>}
   */
  const forgotPassword = async (email) => {
    const response = await axios.get(`/auth/resetpassword/email/${email}`);
    return response.data;
  };

  /**
   * Updates the user profile.
   */
  const updateProfile = () => {};

  /**
   * Logs out the user.
   */
  const logout = () => {
    setBearerToken(null);
    setSession(null);
    dispatch({ type: LOGOUT });
    clearLocalStorage();
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FrogAuthContext.Provider
      value={{
        ...state,
        checkUserStatus,
        checkUserStatusEmail,
        validateResetPasswordLink,
        resetPassword,
        changePassword,
        login,
        preregisteremail,
        prevalidateemail,
        preregisterphonenumber,
        prevalidatephonenumber,
        register,
        emailVerification,
        resendEmailCode,
        phoneVerification,
        resendPhoneCode,
        logout,
        forgotPassword,
        updateProfile
      }}
    >
      {children}
    </FrogAuthContext.Provider>
  );
};

FrogProvider.propTypes = {
  children: PropTypes.node
};

export default FrogAuthContext;
