import { useLocation, useNavigate } from "react-router-dom";

// project-imports
import { APP_DEFAULT_PATH } from "config";
import PropTypes from "prop-types";
import { get } from "lodash";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const usertype = get(user, "data[0].useraccount.usertype");
  // console.log("🚀 ~ GuestGuard ~ usertype:", usertype);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      if (usertype === "ADMIN" || usertype === "STAFF") {
        navigate(location?.state?.from ? location?.state?.from : "/admin/dashboard", {
          state: {
            from: ""
          },
          replace: true
        });
      } else {
        navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
          state: {
            from: ""
          },
          replace: true
        });
      }
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
