// project-imports

// third-party
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],

  isLoading: false
};

// ==============================|| SLICE - users ||============================== //

const adminusers = createSlice({
  name: "adminusers",
  initialState,
  reducers: {
    isLoading(state, action) {
      state.isLoading = action.payload;
    },

    // add new user
    addUserList(state, action) {
      state.users.push(action.payload);
    },

    // delete user
    deleteUserList(state, action) {
      state.users = state.users.filter((item) => item.id !== action.payload);
    },

    // update user
    updateUserList(state, action) {
      const user = action.payload;
      const userUpdate = state.users.map((item) => {
        if (item.id === user.id) {
          return user;
        }
        return item;
      });
      state.users = userUpdate;
    },

    // Activate user
    activateUser(state, action) {
      const index = state.users.findIndex((user) => user.id === action.payload);
      if (index !== -1) {
        state.users[index].status = "active";
      }
    },

    // Deactivate user
    deactivateUser(state, action) {
      const index = state.users.findIndex((user) => user.id === action.payload);
      if (index !== -1) {
        state.users[index].status = "deactivated";
      }
    }
  }
});

export default adminusers.reducer;

export const { addUserList, deleteUserList, isLoading, activateUser, deactivateUser, updateUserList } = adminusers.actions;
