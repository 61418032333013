// import admin from "./admin";
// import client from "./client";
// import clients from "./clients";
import admin from "./admin";
// import dashboard from "./fdashboard";
// import widget from './bulk-messages';
// import { get } from "lodash";
// import services from "./services";
// import settings from "./settings";
// project-imports
// import applications from './applications';
// import chartsMap from './charts-map';
// import formsTables from './forms-tables';
// import support from './support';
// import pages from './pages';

// const user = JSON.parse(localStorage.getItem("user"));
// console.log("🚀 ~ user:", user);
// const usertype = get(user, "data[0].useraccount.usertype");

// ==============================|| MENU ITEMS ||============================== //

const adminItems = {
  items: [admin]
};

// // Check user type and modify adminItems accordingly
// if (user && usertype === "admin") {
//   // If user type is admin, include additional menu items
//   adminItems.items.push(admin);
// } else if (user && usertype === "CLIENT") {
//   // If user type is client, include additional menu items
//   adminItems.items.push(dashboard);
//   adminItems.items.push(services);
//   adminItems.items.push(settings);
// }

export default adminItems;
