import { createSlice } from '@reduxjs/toolkit';

// initial state
export const initialState = {
  error: null,
  voiceMessage: [],
  emailMessage: []
};

const anniversary = createSlice({
  name: 'anniversary',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // VOICE MESSAGES
    getVoiceMessage(state, action) {
      state.voiceMessage = action.payload;
    },

    addVoiceMessage(state, action) {
      state.voiceMessage = [...state.voiceMessage, action.payload];
    },

    // EMAIL MESSAGES
    getEmailMessage(state, action) {
      state.emailMessage = action.payload;
    },

    addEmailMessage(state, action) {
      state.emailMessage = [...state.emailMessage, action.payload];
    },

    resetVoice(state) {
      state.voiceMessage = initialState.voiceMessage;
    }
  }
});

// Reducer
export default anniversary.reducer;

export const { hasError, getVoiceMessage, addVoiceMessage, getEmailMessage, addEmailMessage, resetVoice } = anniversary.actions;
