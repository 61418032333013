// assets
import { Book, Cards, Fatrows, PresentionChart, Setting2, Story } from "iconsax-react";

// third-party
import { FormattedMessage } from "react-intl";

// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  contact: Book,
  credits: Cards,
  settings: Setting2
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const Settings = {
  id: "settings-menu",
  icon: icons.widgets,
  title: <FormattedMessage id="Settings" />,
  type: "group",
  children: [
    {
      id: "profile",
      title: <FormattedMessage id="Profile" />,
      type: "item",
      url: "/user/profile",
      icon: icons.chart
    }
    // {
    //   id: "settings",
    //   title: <FormattedMessage id="Settings" />,
    //   type: "item",
    //   url: "user/settings/general",
    //   icon: icons.settings
    // }
  ]
};

export default Settings;
