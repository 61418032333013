// project-imports
// import CommonLayout from 'layout/CommonLayout';
// import Loadable from 'components/Loadable';
import AdminRoutes from "./AdminRoutes";
import ClientRoutes from "./ClientRoutes";
// import ComponentsRoutes from "./ComponentsRoutes";
import LoginRoutes from "./LoginRoutes";
// import MainRoutes from "./MainRoutes";
// import { lazy } from 'react';
import { useRoutes } from "react-router-dom";

// render - landing page
// const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  // const {user} = useAuth()
  return useRoutes([LoginRoutes, AdminRoutes, ClientRoutes]);
}
