// material-ui
import { Stack, Typography } from "@mui/material";

// import { Link as RouterLink } from 'react-router-dom';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const currentYear = new Date().getFullYear();

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: "24px 16px 0px", mt: "auto" }}>
    <Typography variant="caption">&copy;{currentYear} FROG ♥ by Wigal</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      {/* <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
        Home
      </Link> */}
      {/* <Link
        component={RouterLink}
        to="https://phoenixcoded.gitbook.io/able-pro/v/react/"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Documentation
      </Link>
      <Link component={RouterLink} to="https://phoenixcoded.authordesk.app/" target="_blank" variant="caption" color="textPrimary">
        Support
      </Link> */}
    </Stack>
  </Stack>
);

export default Footer;
