// project-imports
// import NavCard from './NavCard';
import Navigation from "./Navigation";
import SimpleBar from "components/third-party/SimpleBar";
// material-ui
// import { useMediaQuery, useTheme } from '@mui/material';
// import { useTheme } from '@mui/material';

// import { useSelector } from 'store';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  // const theme = useTheme();
  // const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // const { drawerOpen } = useSelector((state) => state.menu);

  return (
    <SimpleBar
      sx={{
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column"
        }
      }}
    >
      <>
        <Navigation />
        {/* {drawerOpen && !matchDownMD && <NavCard />} */}
      </>
    </SimpleBar>
  );
};

export default DrawerContent;
