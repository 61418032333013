import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: [],
  error: null
};

// Create a slice
const senderId = createSlice({
  name: 'senderId',
  initialState,
  reducers: {
    getSenderIds: (state, action) => {
      state.lists = action.payload;
    },
    createSenderId: (state, action) => {
      // state.lists = [...state.lists, action.payload];
      state.lists = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

// Export reducer as default
export default senderId.reducer;

// Export actions
export const { getSenderIds, createSenderId, setError } = senderId.actions;
