// fonts
import "assets/fonts/inter/inter.css";
// scroll bar
import "simplebar/dist/simplebar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// apex-chart
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persister, store } from "store";

// project-imports
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "contexts/ConfigContext";
import { PersistGate } from "redux-persist/integration/react";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider as ReduxProvider } from "react-redux";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// third-party

const container = document.getElementById("root");
const root = createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <QueryClientProvider client={queryClient}>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <ConfigProvider>
          <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </PersistGate>
    </ReduxProvider>
    {/* <ReactQueryDevtools initialIsOpen={true} /> */}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
