// project-imports

// third-party
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: {},
  subcompany: {},

  isLoading: false,
  alertPopup: false
};

// ==============================|| SLICE - company ||============================== //

const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },

    isLoading(state, action) {
      state.isLoading = action.payload;
    },

    // get all company list
    getCompanyList(state, action) {
      state.company = action.payload;
    },

    // add new company
    addCompanyList(state, action) {
      // const newCompany = action.payload;
      state.company = action.payload;
    },
    addSubCompanyList(state, action) {
      // const newCompany = action.payload;
      state.subcompany = action.payload;
    },

    // delete company
    deleteCompanyList(state, action) {
      state.company = state.company.filter((item) => item.id !== action.payload);
    }
  }
});

export default company.reducer;

export const { isLoading, getCompanyList, addCompanyList, deleteCompanyList, alertPopupToggle, addSubCompanyList } = company.actions;
