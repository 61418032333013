// assets
import {
  AudioSquare,
  Book,
  CalendarEdit,
  Cards,
  Fatrows,
  Message,
  MessageAdd1,
  NotificationFavorite,
  PresentionChart,
  Send2,
  Story
} from "iconsax-react";

// third-party
import { FormattedMessage } from "react-intl";

// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  message: Message,
  addMsg: MessageAdd1,
  sent: Send2,
  schedule: CalendarEdit,
  alert: NotificationFavorite,
  audio: AudioSquare,
  contact: Book,
  credits: Cards
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const services = {
  id: "services-menu",
  title: <FormattedMessage id="Services" />,
  icon: icons.message,
  type: "group",
  children: [
    {
      id: "bulk-messaging",
      title: <FormattedMessage id="Bulk Messaging" />,
      type: "collapse",
      icon: icons.message,
      children: [
        {
          id: "new_message",
          title: (
            <>
              <FormattedMessage id="Send Message" />
            </>
          ),
          type: "item",
          // icon: icons.addMsg,
          url: "/bulk-messaging/send-message"
        },
        {
          id: "sent_msgs",
          title: (
            <>
              <FormattedMessage id="Sent Messages" />
            </>
          ),
          type: "item",
          // icon: icons.sent,
          url: "/bulk-messaging/sent-messages"
        },
        {
          id: "schedule-a-message",
          title: (
            <>
              <FormattedMessage id="Schedule A Message" />
            </>
          ),
          type: "item",
          // icon: icons.alert,
          url: "/bulk-messaging/schedule-a-message/schedule"
        },
        {
          id: "scheduled_msgs",
          title: (
            <>
              <FormattedMessage id="Scheduled Messages" />
            </>
          ),
          type: "item",
          // icon: icons.schedule,
          url: "/bulk-messaging/scheduled-messages"
        },

        // {
        //   id: "audio-library",
        //   title: (
        //     <>
        //       <FormattedMessage id="Audio Library" />
        //     </>
        //   ),
        //   type: "item",
        //   // icon: icons.audio,
        //   url: "/bulk-messaging/audio-library"
        // },
        // {
        //   id: "ivr_campaigns",
        //   title: (
        //     <>
        //       <FormattedMessage id="IVR Campaigns" />
        //     </>
        //   ),
        //   type: "item",
        //   url: "/bulk-messaging/ivr-campaigns"
        // },
        {
          id: "sender_id",
          title: (
            <>
              <FormattedMessage id="Sender IDs" />
            </>
          ),
          type: "item",
          url: "/bulk-messaging/senderids"
          //  breadcrumbs: false
        }
      ]
    },
    {
      id: "address-book",
      title: <FormattedMessage id="Address Book" />,
      type: "collapse",
      icon: icons.contact,
      children: [
        {
          id: "manage_grps",
          title: (
            <>
              <FormattedMessage id="Manage Groups" />
            </>
          ),
          type: "item",
          url: "/address-book/groups"
        },
        {
          id: "bulk_contact",
          title: (
            <>
              <FormattedMessage id="Import Bulk Contacts" />
            </>
          ),
          type: "item",
          url: "/address-book/bulk-imports"
        }
      ]
    },
    {
      id: "credits",
      title: <FormattedMessage id="Credits" />,
      type: "collapse",
      icon: icons.credits,
      children: [
        {
          id: "balance",
          title: (
            <>
              <FormattedMessage id="Balance" />
            </>
          ),
          type: "item",
          url: "/credits/balance"
        },
        {
          id: "purchase_bundle",
          title: (
            <>
              <FormattedMessage id="Purchase Bundles" />
            </>
          ),
          type: "item",
          url: "/credits/bundles"
        },
        {
          id: "invoices",
          title: (
            <>
              <FormattedMessage id="Invoices" />
            </>
          ),
          type: "item",
          url: "/credits/invoices"
        }
      ]
    }
  ]
};

export default services;
