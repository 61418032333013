// import { useMemo } from 'react';

// material-ui
import { Box, Button, Stack, useMediaQuery } from "@mui/material";

import AnimateButton from "components/@extended/AnimateButton";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";
import { Link } from "react-router-dom";
import { MenuOrientation } from "config";
import { MirroringScreen } from "iconsax-react";
// import MobileSection from "./MobileSection";
// import Localization from './Localization';
// import Notification from "./Notification";
// import Message from './Message';
import Profile from "./Profile";
import PurchaseBundle from "./PurchaseBundle";
// import PurchaseBundle from './PurchaseBundle';
// project-imports
// import Search from './Search';
import useConfig from "hooks/useConfig";
import useUserAuthInfo from "hooks/useAuthInfo";

// import MegaMenuSection from './MegaMenuSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();
  const { userType } = useUserAuthInfo();
  // const { i18n, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}

      {userType === "ADMIN" || userType === "STAFF" ? (
        !downLG && <Box sx={{ width: "100%", ml: { xs: 0, md: 2 } }} />
      ) : (
        <Box sx={{ width: "100%", ml: { xs: 0, md: 2 } }}>
          {!downLG && (
            <Stack direction="row" spacing={2}>
              <PurchaseBundle title="Add Prepaid Credit" />
              <Link to="/credits/bundles">
                <AnimateButton>
                  <Button variant="contained" color="primary" startIcon={<MirroringScreen />}>
                    Purchase Bundle
                  </Button>
                </AnimateButton>
              </Link>
            </Stack>
          )}
        </Box>
      )}

      {/* {!downLG && megaMenu}
      {!downLG && localization} */}
      {/* {downLG && <Box sx={{ width: '100%', ml: 1 }} />} */}
      {downLG && <Box sx={{ width: "100%", ml: 1 }} />}

      {/* <Notification /> */}

      {/* <Message /> */}
      <Profile />
      {/* {downLG && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
