// action - state management
import { FORGOTPASSWORD, HASERROR, LOGIN, LOGOUT, REGISTER_CLIENT_FAIL, REGISTER_CLIENT_SUCCESS, USERSTATUS, VERIFYEMAIL } from "./actions";

// initial state
export const initialState = {
  error: null,
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  clientid: null,
  token: null,
  emailCode: null,
  phoneCode: null,
  forgotpassword: null,
  userStatus: null
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action) => {
  switch (action.type) {
    case HASERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case REGISTER_CLIENT_SUCCESS: {
      return {
        ...state,
        user: action.payload
      };
    }
    case REGISTER_CLIENT_FAIL: {
      return {
        ...state,
        error: action.payload
      };
    }
    case USERSTATUS: {
      return {
        ...state,
        userStatus: action.payload
      };
    }
    case FORGOTPASSWORD: {
      return {
        ...state,
        forgotpassword: action.payload
      };
    }

    case VERIFYEMAIL: {
      const { emailCode } = action.payload;
      return {
        ...state,
        emailCode
      };
    }
    case LOGIN: {
      const { user, clientid, token } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
        clientid,
        token
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
        clientid: null,
        token: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
