// assets
import { Dash, Fatrows, PresentionChart, Story } from "iconsax-react";

// third-party
import { FormattedMessage } from "react-intl";

// icons
const icons = {
  widgets: Story,
  dashboard: Dash,
  data: Fatrows,
  chart: PresentionChart
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const dashboard = {
  id: "dashboard-menu",
  // title: <FormattedMessage id="dashboard" />,
  icon: icons.widgets,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.dashboard
    }
    // {
    //   id: 'data',
    //   title: <FormattedMessage id="data" />,
    //   type: 'item',
    //   url: '/widget/data',
    //   icon: icons.data
    // },
    // {
    //   id: 'chart',
    //   title: <FormattedMessage id="chart" />,
    //   type: 'item',
    //   url: '/widget/chart',
    //   icon: icons.chart
    // }
  ]
};

export default dashboard;
