// project import
import { ThemeMode } from "config";

// ==============================|| PRESET THEME - THEME3 ||============================== //

const Theme4 = (mode) => {
  const contrastText = "#fff";

  let primaryColors = ["#E6F3EC", "#84c297", "#5eb57d", "#3da866", "#1f9c53", "#65bc4b", "#006933", "#004222", "#001c0f", "#000000"];
  let secondaryColors = ["#f9fcf8", "#F8F9FA", "#F3F5F7", "#DBE0E5", "#BEC8D0", "#8996A4", "#5B6B79", "#21438d", "#1D2630", "#131920"];
  let errorColors = ["#FDE8E7", "#F25E52", "#eb1e25", "#EE3B2F", "#E92A21"];
  let warningColors = ["#FFF7E0", "#FFC926", "#f58120", "#FFB900", "#FFA900"];
  let infoColors = ["#E0F4F5", "#26B0BA", "#1cc2f2", "#009AA7", "#008694"];
  let successColors = ["#E0F5EA", "#26B56E", "#00A854", "#00A04D", "#008D3A"];

  if (mode === ThemeMode.DARK) {
    primaryColors = ["#1a231f", "#173123", "#174029", "#13502f", "#0e6737", "#0a7d3e", "#1f8f4e", "#3ba162", "#5cb07a", "#82bf95"];
    secondaryColors = ["#131920", "#ecf0f5", "#3E4853", "#5B6B79", "#8996A4", "#BEC8D0", "#DBE0E5", "#F3F5F7", "#F8F9FA", "#F8F9FA"];
    errorColors = ["#321d1d", "#7d2e28", "#d13c31", "#e66859", "#f8baaf"];
    warningColors = ["#342c1a", "#836611", "#dda705", "#e9bf28", "#f8e577"];
    infoColors = ["#1a2628", "#11595f", "#058e98", "#1ea6aa", "#64cfcb"];
    successColors = ["#1a2721", "#115c36", "#05934c", "#1da65d", "#61ca8b"];
  }

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText
    },
    secondary: {
      lighter: secondaryColors[0],
      100: secondaryColors[1],
      200: secondaryColors[2],
      light: secondaryColors[3],
      400: secondaryColors[4],
      500: secondaryColors[5],
      main: secondaryColors[6],
      dark: secondaryColors[7],
      800: secondaryColors[8],
      darker: secondaryColors[9],
      contrastText
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: secondaryColors[5]
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText
    }
  };
};

export default Theme4;
