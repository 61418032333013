// project-imports

// third-party
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  success: null,
  destinations: [],
  invoices: [],
  invoice: null,
  fees: [],
  terms: [],
  providerRate: [],
  isLoading: false,
  alertPopup: false
};

// ==============================|| SLICE - Billing ||============================== //

const billing = createSlice({
  name: "billing",
  initialState,
  reducers: {
    // has Error
    setError(state, action) {
      state.error = action.payload;
    },

    // get destinations

    setDestinations(state, action) {
      state.destinations = action.payload;
    },

    setInvoicesSuccess(state, action) {
      state.success = action.payload;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },
    // get all fee list
    getFeeList(state, action) {
      state.fees = action.payload;
    },

    isLoading(state, action) {
      state.isLoading = action.payload;
    },

    // add new fee
    addFeeList(state, action) {
      state.fees.push(action.payload);
    },

    // add new term
    addTermList(state, action) {
      state.terms.push(action.payload);
    },

    // add new provider rate
    addProviderRate(state, action) {
      state.providerRate.push(action.payload);
    },

    // delete fee
    deleteFeeList(state, action) {
      state.fees = state.fees.filter((item) => item.id !== action.payload);
    },
    // delete fee
    deleteRatePlanList(state, action) {
      state.providerRate = state.providerRate.filter((item) => item.id !== action.payload);
    },

    // delete term
    deleteTermList(state, action) {
      state.terms = state.terms.filter((item) => item.numOfDays !== action.payload);
    }
  }
});

export default billing.reducer;

export const {
  addFeeList,
  deleteFeeList,
  setInvoicesSuccess,
  setDestinations,
  deleteRatePlanList,
  isLoading,
  addTermList,
  addProviderRate,
  deleteTermList
} = billing.actions;

// function to fetch all destinations it requires a dispatch function, bearer token

export const getDestinationsList = (token) => async (dispatch) => {
  try {
    const response = await fetch("/billing/destinations", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });
    dispatch(setDestinations(response.data));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
