import addressbook from "./addressbook";
import adminusers from "./adminusers";
import anniversary from "./anniversary";
import billing from "./billing";
import calendar from "./calendar";
// project-imports
import chat from "./chat";
import clients from "./clients";
// third-party
import { combineReducers } from "redux";
import companyReducer from "./company";
import invoice from "./invoice";
import kanban from "./kanban";
import menu from "./menu";
import messaging from "./messaging";
import options from "./options";
import { persistReducer } from "redux-persist";
import productReducer from "./product";
import senderId from "./senderid";
import setups from "./setups";
import snackbar from "./snackbar";
import storage from "redux-persist/lib/storage";
import usersmanagement from "./users";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,

  product: productReducer,
  kanban,
  invoice,
  anniversary,
  senderId,
  clients,
  billing,
  setups,
  adminusers,
  contacts: addressbook,
  company: persistReducer(
    {
      key: "company",
      storage,
      keyPrefix: "user-company"
    },
    companyReducer
  ),
  options,
  usersmanagement,
  messaging
});

export default reducers;
