import axios from "axios";
import { get } from "lodash";

const axiosServices = axios.create({ baseURL: "https://frogtestapi.wigal.com.gh" });

const bearerToken = localStorage.getItem("bearerToken");

// Function to check token expiry
const isTokenExpired = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const tokenExpiration = get(user, "data[0].accessToken.expiresOn");

  if (!tokenExpiration) {
    return true; // Token expiration information not available
  }

  const currentTime = new Date().getTime();
  return currentTime > tokenExpiration;
};

// Intercept requests to add Authorization header
axiosServices.interceptors.request.use((config) => {
  if (bearerToken && !isTokenExpired()) {
    config.headers.Authorization = `Bearer ${bearerToken}`;
  }
  return config;
});

// Intercept responses to handle token expiration
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log("eeeeeeeee", error.response.data.message);
    if (error.response && error.response.status === 401 && !window.location.href.includes("/login")) {
      // Clear token and user data
      localStorage.removeItem("bearerToken");
      localStorage.removeItem("user");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("adminClientId");

      // Redirect to session expired page
      window.location.pathname = "/session-expired";
    } else if (error.response && error.response.data.message === "You are not authorized to perform action requested on resource") {
      localStorage.removeItem("bearerToken");
      localStorage.removeItem("user");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("adminClientId");
      // Redirect to the error page for permission denied
      window.location.pathname = "/403";
    } else if (error.response && error.response.status === 500 && !window.location.href.includes("/login")) {
      window.location.pathname = "/500";
    }
    return Promise.reject((error.response && error.response.data) || "Wrong Services");
  }
);

export default axiosServices;
