import CommonLayout from "layout/CommonLayout";
// project-imports
import GuestGuard from "utils/route-guard/GuestGuard";
import Loadable from "components/Loadable";
import { lazy } from "react";

// render - login
// const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
const AuthLogin = Loadable(lazy(() => import("pages/auth/auth2/login2")));
const AuthRegister = Loadable(lazy(() => import("pages/auth/auth1/register")));
const AuthForgotPassword = Loadable(lazy(() => import("pages/auth/auth2/forgot-password2")));
const AuthCheckMail = Loadable(lazy(() => import("pages/auth/auth1/check-mail")));
const AuthResetPassword = Loadable(lazy(() => import("pages/auth/auth2/reset-password2")));
const AuthCodeVerification = Loadable(lazy(() => import("pages/auth/auth2/code-verification2")));
const AuthPhoneVerification = Loadable(lazy(() => import("pages/auth/auth2/phone-verification")));
const AuthValidateEmail = Loadable(lazy(() => import("pages/auth/register/email")));
const AuthValidatePhone = Loadable(lazy(() => import("pages/auth/register/phone")));
const RegisterEmailCodeVerification = Loadable(lazy(() => import("pages/auth/register/email/otp")));
const RegisterPhoneCodeVerification = Loadable(lazy(() => import("pages/auth/register/phone/otp")));
const AccountVerified = Loadable(lazy(() => import("pages/auth/auth1/account-verified")));
// const PaymentCallback = Loadable(lazy(() => import("pages/payment-callback")));
const Error403 = Loadable(lazy(() => import("pages/maintenance/error/403")));

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "/",
          element: <AuthLogin />
        },
        // {
        //   path: "payment-callback",
        //   element: <PaymentCallback />
        // },
        {
          path: "login",
          element: <AuthLogin />
        },
        {
          path: "register/validate-email",
          element: <AuthValidateEmail />
        },
        {
          path: "register/validate-email/:refcode",
          element: <AuthValidateEmail />
        },
        {
          path: "register/validate-email/otp",
          element: <RegisterEmailCodeVerification />
        },
        {
          path: "register/validate-phone",
          element: <AuthValidatePhone />
        },
        {
          path: "register/verify-success",
          element: <AccountVerified />
        },
        {
          path: "register/validate-phone/otp",
          element: <RegisterPhoneCodeVerification />
        },

        {
          path: "register",
          element: <AuthRegister />
        },

        {
          path: "forgot-password",
          element: <AuthForgotPassword />
        },
        {
          path: "check-mail",
          element: <AuthCheckMail />
        },
        {
          path: "reset-password/:encrypteduserid/:encryptedtoken",
          element: <AuthResetPassword />
        },
        {
          path: "code-verification",
          element: <AuthCodeVerification />
        },
        {
          path: "phone-verification",
          element: <AuthPhoneVerification />
        }
      ]
    },
    {
      path: "403",
      element: <Error403 />
    }
  ]
};

export default LoginRoutes;
