// project-imports

// third-party
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  plans: [],
  tax: [],
  limits: [],
  isLoading: false
};

// ==============================|| SLICE - clients ||============================== //

const clients = createSlice({
  name: "clients",
  initialState,
  reducers: {
    isLoading(state, action) {
      state.isLoading = action.payload;
    },

    // add new plan
    addPlan(state, action) {
      state.plans.push(action.payload);
    },

    // delete service
    deletePlan(state, action) {
      state.plans = state.plans.filter((item) => item.id !== action.payload);
    },

    // Activate service
    activateService(state, action) {
      const index = state.services.findIndex((service) => service.id === action.payload);
      if (index !== -1) {
        state.services[index].status = "active";
      }
    },

    // Deactivate service
    deactivateService(state, action) {
      const index = state.services.findIndex((service) => service.id === action.payload);
      if (index !== -1) {
        state.services[index].status = "deactivated";
      }
    }
  }
});

export default clients.reducer;

export const { addPlan, deletePlan, isLoading, activateService, deactivateService } = clients.actions;
